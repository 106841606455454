import { ProductPriceMap } from 'prices/types'

import { Cart, CartLineItem, CartManager } from 'cart'
import { PriceManager } from 'prices'
import { Currency } from 'prices/types'
import { createCartLineItemFromLineItem, OFFERED_MEMBERSHIPS } from 'products/utils'
import { getVariantPrices } from 'stores/prices/prices.utils'
import { convertShopifyToStripe } from 'stripe_lib/constants'
import { RegionShort } from 'utils/internationalization'
import { CartSliceMini } from './cart.types'

export const getCartTotal = (fullCartItems: CartLineItem[]) => {
	return fullCartItems.reduce((acc, item) => {
		return acc + item.price * item.quantity
	}, 0)
}

export const getCartSubtotal = (fullCartItems: CartLineItem[]) => {
	return fullCartItems.reduce((acc, item) => {
		return acc + item.comparePrice * item.quantity
	}, 0)
}

export const getMostExpensivePodOrCoverInCart = (fullCartItems: CartLineItem[], cartManager: CartManager) => {
	if (!cartManager) {
		return null
	}
	return cartManager.getMostExpensivePodOrCoverInCart(fullCartItems)
}

export const getCartNumItems = ({ cartManager, cart }: CartSliceMini) => {
	if (!cartManager || !cart) {
		return 0
	}
	return cartManager.getNumItems(cart)
}

export const getFullCartItems = (cart: Cart, currency: Currency, allPrices: ProductPriceMap, priceManager: PriceManager) => {
	return cart.lineItems
		.filter((item) => !!item)
		.map((item) => {
			return createCartLineItemFromLineItem(item, PriceManager.formatPrices(getVariantPrices(item.variantId, allPrices, priceManager), currency))
		})
		.filter((item) => !!item)
}

export const getCartItemNames = (cartManager: CartManager, fullCartItems: CartLineItem[]) => {
	return cartManager.getItemNames(fullCartItems)
}

export const getCartItemVariants = ({ cartManager, cart }: CartSliceMini) => {
	return cartManager.getItemVariants(cart) ?? []
}

export const getCartHasPodOrCover = ({ cartManager, cart }: CartSliceMini) => {
	if (!cartManager || !cart) {
		return false
	}
	return cartManager.cartContainsPodOrCover(cart)
}

export const getCartHasSleepElixir = ({ cartManager, cart }: CartSliceMini) => {
	if (!cartManager || !cart) {
		return false
	}
	return cartManager.cartContainsSleepElixir(cart)
}

export const getCartSleepElixirLineItems = ({ cartManager, cart }: CartSliceMini) => {
	if (!cartManager || !cart) {
		return []
	}
	return cartManager.getSleepElixirLineItems(cart)
}

export const getCartNumPods = ({ cartManager, cart }: CartSliceMini) => {
	if (!cartManager || !cart) {
		return 0
	}
	return cartManager.getNumPods(cart)
}

export const getCartNumCovers = ({ cartManager, cart }: CartSliceMini) => {
	if (!cartManager || !cart) {
		return 0
	}
	return cartManager.getNumCovers(cart)
}

export const getCartHasTechCover = ({ cartManager, cart }: CartSliceMini) => {
	if (!cartManager || !cart) {
		return false
	}
	return cartManager.cartContainsCover(cart)
}

export const getCartHasMembership = ({ cartManager, cart }: CartSliceMini) => {
	if (!cartManager || !cart) {
		return false
	}
	return cartManager.cartContainsMembership(cart)
}

export const getCartHasMemberUpgradeInCart = ({ cartManager, cart }: CartSliceMini) => {
	if (!cartManager || !cart) {
		return false
	}
	return cartManager.cartContainsMemberUpgrade(cart)
}

export const getCartNumAccessories = ({ cartManager, cart }: CartSliceMini) => {
	const numItems = getCartNumItems({ cartManager, cart })
	const numPodsAndCovers = getCartNumPodsAndCovers({ cartManager, cart })
	const numBundles = getCartNumBundles({ cartManager, cart })
	return numItems - numPodsAndCovers - numBundles
}

export const getCartNumBundles = ({ cartManager, cart }: CartSliceMini) => {
	if (!cartManager || !cart) {
		return 0
	}
	return cartManager.getNumBundles(cart)
}

export const getCartNumPodsAndCovers = ({ cartManager, cart }: CartSliceMini) => {
	if (!cartManager || !cart) {
		return 0
	}
	return cartManager.getNumPodsAndCovers(cart)
}

export const getCartNumPodsAndCoversLines = ({ cartManager, cart }: CartSliceMini) => {
	if (!cartManager || !cart) {
		return 0
	}
	return cartManager.getNumPodsAndCoversLines(cart)
}

export const getIndexOfCurrentMembership = ({ cartManager, cart }: CartSliceMini) => {
	const hasMembership = getCartHasMembership({ cartManager, cart })
	if (!hasMembership) {
		return -1
	}

	const membershipID = cartManager.membershipID(cart)

	const offeredMemberships = OFFERED_MEMBERSHIPS()
	for (let i = 0; i < offeredMemberships.length; i++) {
		const offering = offeredMemberships[i]

		if (offering.variants.standard.id === membershipID) {
			return i
		}
	}

	return -1
}

export const getCartDiscountedMattresses = ({ cartManager, cart }: CartSliceMini) => {
	if (!cartManager || !cart) {
		return []
	}
	return cartManager.getCartDiscountedMattresses(cart)
}

export const getCartBaseUpgrades = ({ cartManager, cart }: CartSliceMini) => {
	if (!cartManager || !cart) {
		return []
	}
	return cartManager.getCartBaseUpgrades(cart)
}

export const getCombinedCartItemsForAbTest = (cartManager: CartManager, fullCartItems: CartLineItem[]) => {
	return cartManager.getCombinedCartItemsForAbTest(fullCartItems)
}

export const createCheckoutAndRedirect = (region: RegionShort, discountCode: string, fullCartItems: CartLineItem[]) => {
	const currency = PriceManager.getRegionCurrency(region)
	const lines = fullCartItems.map((li) => ({ variantId: li.variantId, quantity: li.quantity, attributes: li.attributes }))

	const shopifyData = {
		lines,
		region,
		coupons: [...discountCode.split(',')],
		currency,
		email: sessionStorage.getItem('customer_email'),
	}

	try {
		const stripeData = convertShopifyToStripe(shopifyData)
		localStorage.setItem('stripeInvoice', JSON.stringify(stripeData))
		localStorage.setItem('truemed_data', null)

		if (region === 'mx' || region === '' || window.location.hostname.includes('.com.mx')) {
			return '/checkout'
		}

		return `/${region}/checkout`
	} catch (error) {
		console.error(error)
		return false
	}
}
